// hardcode base API url, instead of using .env
// link: https://data.gov.sg/dataset/weekly-infectious-disease-bulletin-cases
const baseURL = 'https://data.gov.sg/api/action/datastore_search'

const $fetch = {
  async get (params) {
    const url = `${baseURL}?${new URLSearchParams(params).toString()}`

    try {
      // fetch API, first returns Response, then Data
      const response = await fetch(url, {
        method: 'GET' // default is GET; declare explicitly
        // ...options, // Request options
      })

      // check if response.ok, because it only rejects on network failure
      if (!response.ok) {
        throw new Error(`${response.status} ${response.statusText}`)
      }

      return response.json() // native method - resolves and parses the request body as JSON.
    } catch (error) {
      // console.log('API down', error)
      throw new Error(error)
    }
  }
//   post () {}
}

export default $fetch
