<template>
  <div id="app">
    <img alt="GovTech singapore logo" src="https://d33wubrfki0l68.cloudfront.net/7b7e8b84b8180770131a2838266cc18409b22293/545c3/images/logo_govtech_hort.gif">

    <div>
      <img src="@/assets/logo.svg" alt="data.gov.sg logo" class="d-inline-block">
      <h2 class="d-inline-block">data.gov.sg</h2>
    </div>

    <!-- show data -->
    <table class="styled-table">
      <thead>
        <tr>
          <th>epi_week</th>
          <th>disease</th>
          <th>no_of_cases</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, i) in items" :key="i">
          <td>{{ item.epi_week }}</td>
          <td>{{ item.disease }}</td>
          <td>{{ item['no._of_cases'] }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: 'App',
  inject: ['$services'],
  data () {
    return {
      items: null
    }
  },
  created () {
    const params = {
      // resource_id: 'ef7e44f1-9b14-4680-a60a-37d2c9dda390', // the resource id
      limit: 11, // get 11 results
      q: '2021'
    }
    this.fetchDisease(params)
  },
  methods: {
    async fetchDisease (params) {
      this.items = await this.$services.epi.get(params)
    }
  }
}
</script>

<style>
.styled-table {
    border-collapse: collapse;
    margin: 25px 0;
    font-size: 0.9em;
    font-family: sans-serif;
    min-width: 400px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.styled-table thead tr {
    background-color: #3d68bd;
    color: #ffffff;
    text-align: left;
}

.styled-table th,
.styled-table td {
    padding: 12px 15px;
}

.styled-table tbody tr {
    border-bottom: 1px solid #dddddd;
}

.styled-table tbody tr:nth-of-type(even) {
    background-color: #f3f3f3;
}

.styled-table tbody tr:last-of-type {
    border-bottom: 2px solid #3d68bd;
}

.styled-table tbody tr.active-row {
    font-weight: bold;
    color: #3d68bd;
}

.d-inline-block {
  display: inline-block;
}
</style>
