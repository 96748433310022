
import $fetch from './apiClient'

const resource = 'ef7e44f1-9b14-4680-a60a-37d2c9dda390'

export default {
  get (params) {
    return new Promise((resolve, reject) => {
      $fetch.get({
        resource_id: resource,
        ...params
      })
        .then(res => {
          // construct response object
          const responseResult = {
            apiUrl: res.help,
            data: res.result.records
          }
          resolve(responseResult)
        })
        .catch(err => {
          reject(err)
        })
    })
  }
}
