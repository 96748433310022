import EpidemRepository from '../repositories/epidemiological.repository'

const EpidemService = {
  /**
     * Returns array of Infectious Diseases
     * @param {object} params
     * @returns {Promise}
     */
  get (params) {
    return new Promise((resolve, reject) =>
      EpidemRepository.get(params)
        .then(({ data }) => {
          resolve(data)
        })
        .catch((err) => {
          reject(err)
        })
    )
  }
}

export default EpidemService
